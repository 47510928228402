<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <!-- <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col> -->
      <!-- /Left Text-->
      <!-- 
      

      Login-->

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <img
            class="logo"
            src="@/assets/images/celcomLogo/logo-checkout-01.svg"
            alt="Celcom Logo"
          />

          <div class="clearfix"></div>

          <b-card-title title-tag="h2"> Iniciar Sesión </b-card-title>
          <!-- <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Vuexy! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>
 -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <!-- <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div> -->
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-row>
                  <!--  <b-col>
                    <b-form-checkbox
                      class="custom-control-secondary"
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      <label id="recuerdame">Recuérdame</label>
                    </b-form-checkbox>
                  </b-col> -->
                  <b-col class="text-right">
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small>Olvidé mi contraseña</small>
                    </b-link>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="btn-login shadow-none"
                variant="primary"
                block
                :disabled="invalid"
              >
                Ingresar
              </b-button>
              <a
                href="https://checkout.arpi.ar/doc/Documentacion.pdf"
                target="_blank"
                ><b-button
                  class="shadow-none"
                  variant="outline-secondary"
                  block
                >
                  Ir a Documentación
                </b-button></a
              >
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- <div id="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div> -->
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser, getUserData } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import { backendUrl } from "@/config";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      no: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      //this.loading = true;
      this.$store.commit("appConfig/changeLoading", true);
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await useJwt.login({
              email: this.userEmail,
              pass: this.password,
            });
            if (response.data.code === 1) {
              //this.loading = false;
              this.$store.commit("appConfig/changeLoading", false);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Usuario o contraseña incorrectos",
                  icon: "CrossIcon",
                  variant: "danger",
                  text: "Intente nuevamente",
                },
              });
              return;
            } 
            const { data } = response;

            useJwt.setToken(data.user.token);
            data.user.ability = [
              {
                action: "manage",
                subject: "all",
              },
            ];

            localStorage.setItem("userData", JSON.stringify(data.user));
            this.$ability.update(data.user.ability);

            const userLocalStorage = getUserData();

            const token = localStorage.getItem("token");

            const users = await axios({
              method: "get",
              url: `${backendUrl}/usuarios/`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });


            const user = users.data.user;
            const usuario = await user.filter(
              (e) => e.id === userLocalStorage.idUser
            );

            const productosdb = await axios({
              method: "get",
              url: `${backendUrl}/productos/`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            const productos = productosdb.data.project;
            // const productos = []
            if (usuario[0].role != "ADMIN" && productos.length == 0) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "La cuenta todavia no tiene productos.",
                  icon: "CrossIcon",
                  variant: "danger",
                  text: "No puede Ingresar hasta que el administrador cree productos",
                },
              });
              //this.loading = false;
              this.$store.commit("appConfig/changeLoading", false);
              this.$router.push("/login");
            } else if (productos.length > 0) {
              const producto = await productos.filter(
                (e) => e.id === usuario[0].project[0].id_project
              );

              let productosStore = [];

              for (let f = 0; f < usuario[0].project.length; f++) {
                const resProj = await productos.filter(
                  (e) => e.id === usuario[0].project[f].id_project
                );
                for (let k = 0; k < resProj.length; k++) {
                  productosStore.push({
                    id: resProj[k].id,
                    name: resProj[k].name,
                    currency: resProj[k].id_currency,
                    terms: resProj[k].Terms_and_Conditions,
                  });
                }
                productosStore.push({
                  id: resProj[0].id,
                  name: resProj[0].name,
                  currency: resProj[0].id_currency,
                  terms: resProj[0].Terms_and_Conditions,
                });
              }

              this.$store.commit(
                "appConfig/changeProyectosStore",
                productosStore
              );
             
              let auxChangeStore = {
                id: producto[0].id,
                name: producto[0].name,
                currency: producto[0].id_currency,
                terms: producto[0].Terms_and_Conditions,
              };

              this.$store.commit(
                "appConfig/changeStore",
                auxChangeStore 
              );

            

              //GUARDAR USUARIOS
              this.$store.commit("appConfig/saveUsuarios", users.data.user);

              //GUARDAR USUARIO LOGEADO
              this.$store.commit("appConfig/saveUsuarioLogeado", usuario[0]);
              

              //GUARDAR PROYECTOS TOTALES

              this.$store.commit(
                "appConfig/saveProyectosTotales",
                productosdb.data.project
              );

              //trae las monedas
              const responseM = await axios({
                method: "get",
                url: `${backendUrl}/operaciones/util`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              const monedas = responseM.data.currencies;

              this.$store.commit("appConfig/saveCurrency", monedas);

              //TRAER TRANSACCIONES
              const responseT = await axios({
                method: "get",
                url: `${backendUrl}/operaciones/transacciones`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              this.$store.commit(
                "appConfig/saveTransacciones",
                responseT.data.transactions
              );
              //

              //TRAER LINKS DE PAGO
              var config = {
                method: "get",
                url: `${backendUrl}/link-pago/`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };

              const resp = await axios(config);
              this.$store.commit("appConfig/saveLinksDePago", resp.data.link);

              //TRAER SUSCRIPCIONES
              const responseS = await axios({
                method: "get",
                url: `${backendUrl}/operaciones/suscripcion`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              this.$store.commit(
                "appConfig/saveSuscripciones",
                responseS.data.suscriptions
              );
            }
            

            this.$router
              .replace(getHomeRouteForLoggedInUser(data.user.role))
              .then(() => {
                //this.loading = false;
                this.$store.commit("appConfig/changeLoading", false);
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${data.user.name}`,
                    icon: "ThumbsUpIcon",
                    variant: "success",
                    /* text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`, */
                  },
                });
              })
              .catch((e) => {
              });
          } catch (error) {
          }
        }
      });
    },
  },
 
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.btn-login {
  border-color: $primary !important;
  background-color: $primary !important;
  margin-top: 16%;
  margin-bottom: 4%;
}

#btn-docs {
  border-color: $secondary !important;
  color: $secondary !important;
}

h2 {
  display: block;
  text-align: center !important;
  /* font-family: Nunito Sans !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  color: $dark !important;
}

.logo {
  display: block;
  width: 72%;
  margin: auto;
  margin-top: 12%;
  margin-bottom: 32%;
}

.clearfix {
  clear: both;
  float: none;
}

.remember-check {
  color: $secondary !important;
  font-size: small !important;
}

input[type="checkbox"] {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

a {
  color: $dark !important;
}

/* label {
  color: $secondary !important;
} */

#recuerdame {
  color: $secondary !important;
}

.custom-control-label {
  color: $secondary;
}

//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
